<template>
  <div class="home-box">
    <div class="header">
      <div>
        <img src="../../../assets/img/home/logo-V2-04.png" alt="" />
      </div>
      <div class="header-tab" v-if="screenWidth > 768">
        <div class="list">
          <router-link :class="listType == index ? 'listActive' : ''" :to="item.name == 'Home' ? '/' : '/' + item.name" v-for="(item, index) in list" :key="index" @mouseover="showButton(index)" @mouseleave="hideButton(index)" @click="listTab(index)">{{ $t(item.name) }}</router-link>
          <a href="javascript:void(0)" :class="listType == 99 ? 'listActive' : ''" @mouseover="showMobile" @mouseleave="hideMobile">More</a>
          <!-- <router-link to="/missions" v-show="showText" class="listLi" @click="ourMission">我們的使命</router-link> -->
          <div class="modile" v-show="showMobiles">
            <router-link :to="item.name == 'Home' ? '/' : '/' + item.name" v-for="(item, index) in modeList" :key="index" @click="clickMore">{{ $t(item.name) }}</router-link>
          </div>
        </div>
        <div class="btn">
          <a :class="typeActive == 'zh-CN' ? 'i18nActive' : ''" href="javascript:void(0)" @click="change('zh-CN')">CN</a>
          <a :class="typeActive == 'zh-Hant' ? 'i18nActive' : ''" href="javascript:void(0)" @click="change('zh-Hant')">ZH</a>
          <a :class="typeActive == 'en' ? 'i18nActive' : ''" href="javascript:void(0)" @click="change('en')">EN</a>
        </div>
      </div>
      <div class="right-img" v-else>
        <img src="../../../assets/img/home/menu.png" alt="" @click="menu" />
      </div>
    </div>
    <div class="drawerBox">
      <el-drawer v-model="menuShow" direction="rtl" :show-close="false" size="70%">
        <div class="btn">
          <a :class="typeActive == 'zh-CN' ? 'i18nActive' : ''" href="javascript:void(0)" @click="change('zh-CN')">CN</a>
          <a :class="typeActive == 'zh-Hant' ? 'i18nActive' : ''" href="javascript:void(0)" @click="change('zh-Hant')">ZH</a>
          <a :class="typeActive == 'en' ? 'i18nActive' : ''" href="javascript:void(0)" @click="change('en')">EN</a>
        </div>
        <el-row class="tac">
          <el-col>
            <el-menu default-active="2" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose">
              <el-menu-item v-for="(item, index) in listInfo" :key="index" :index="index + 1">
                <el-icon><location /></el-icon>
                <router-link :to="item.name == 'Home' ? '/' : '/' + item.name">{{ $t(item.name) }}</router-link>
              </el-menu-item>
            </el-menu>
          </el-col>
        </el-row>
      </el-drawer>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, defineEmits, defineProps } from "vue";
import { useI18n } from "vue-i18n";
defineProps<Props>();
interface Props {
  listType?: number;
}

/** 获取设备分辨率宽度 */
const screenWidth = ref(document.documentElement.clientWidth);

/** 中英文切换 */
const typeActive = ref("zh-Hant");
const { locale } = useI18n();
const change = (type: string) => {
  locale.value = type;
  typeActive.value = type;
};

/** tab菜单切换 */
const emit = defineEmits(["getValue"]);
const listTab = (index: number) => {
  emit("getValue", index);
};
// const ourMission = () => {
//   emit("getValue", 2);
// };
const clickMore = () => {
  emit("getValue", 99);
};

/** 触发选项 */
const showText = ref(false);
const showButton = (e: string) => {
  if (e == "2") {
    showText.value = true;
  }
};
const hideButton = (e: string) => {
  if (e == "2") {
    setTimeout(() => {
      showText.value = false;
    }, 3000); // 500毫秒，即5秒
  }
};

/** 触发菜单 */
const showMobiles = ref(false);
const showMobile = () => {
  showMobiles.value = true;
};
const hideMobile = () => {
  setTimeout(() => {
    showMobiles.value = false;
  }, 1000); // 500毫秒，即5秒
};

/** 菜单窗口 */
const menuShow = ref(false);
const menu = () => {
  menuShow.value = true;
};
/** tab选项 */
const list = ref([{ name: "Home" }, { name: "aboutUs" }, { name: "services" }, { name: "stores" }]);
const modeList = ref([{ name: "faq" }, { name: "scamCorner" }, { name: "aml" }, { name: "terms" }, { name: "contact" }]);
const listInfo = ref([{ name: "Home" }, { name: "aboutUs" }, { name: "services" }, { name: "stores" }, { name: "faq" }, { name: "scamCorner" }, { name: "aml" }, { name: "terms" }, { name: "contact" }]);
</script>
<style lang="scss" scoped>
@media screen and (min-width: 768px) {
  .home-box {
    width: 100%;
    height: 90px;
    color: #282626;
    text-align: center;
    position: fixed;
    background-color: #fff;
    top: 0;
    left: 0;
    .header {
      width: 920px;
      height: 80px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 80px;
        height: 68px;
      }
      .header-tab {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .list {
          width: 600px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          > a {
            flex: 1;
            color: #605e5e;
            font-size: 0.875rem;
          }
          > a:hover {
            color: #1870eb;
          }
          .listActive {
            color: #1870eb;
          }
          .listLi {
            width: 130px;
            height: 40px;
            line-height: 40px;
            position: absolute;
            bottom: -45px;
            left: 31%;
          }
          .modile {
            position: absolute;
            right: -50px;
            top: 30px;
            a {
              font-size: 14px;
              width: 200px;
              height: 30px;
              line-height: 30px;
              color: #282626;
              background-color: #fff;
            }
            a:hover {
              color: #1870eb;
            }
            .listActive {
              color: #1870eb;
            }
          }
        }
        .btn {
          display: flex;
          align-items: center;
          > a {
            font-size: 14px;
            text-align: center;
            width: 30px;
            height: 20px;
            line-height: 20px;
            background-color: #1870eb;
            color: #fff;
            border: 1px solid #1870eb;
            box-sizing: border-box;
          }
          .i18nActive {
            color: #1870eb;
            background-color: #fff;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .home-box {
    width: 100%;
    height: 5rem;
    color: #282626;
    text-align: center;
    position: fixed;
    background-color: #fff;
    top: 0;
    left: 0;
    .header {
      width: 100%;
      height: 5rem;
      padding: 1.25rem 1.875rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 4.375rem;
        height: 4.5rem;
        vertical-align: middle;
      }
      .right-img {
        img {
          width: 2rem;
          height: 1.6rem;
        }
      }
    }
    .drawerBox {
      :deep(.el-drawer__header) {
        margin: 0;
        padding: 0;
      }
      :deep(.el-menu-item) {
        padding: 0;
      }
      :deep(.el-sub-menu__title) {
        padding: 0;
      }
      .btn {
        display: flex;
        align-items: center;
        > a {
          font-size: 1rem;
          text-align: center;
          width: 1.875rem;
          height: 1.875rem;
          line-height: 1.875rem;
          background-color: #1870eb;
          color: #fff;
          border: 1px solid #1870eb;
          box-sizing: border-box;
        }
        .i18nActive {
          color: #1870eb;
          background-color: #fff;
        }
      }
    }
    a {
      font-size: 14px;
      width: 200px;
      height: 30px;
      line-height: 30px;
      color: #282626;
      background-color: #fff;
    }
    a:hover {
      color: #1870eb;
    }
    .listActive {
      color: #1870eb;
    }
  }
}
</style>
