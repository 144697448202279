<template>
  <div class="index-box">
    <div class="header">
      <headerLayout :listType="listType" @getValue="tabValue"></headerLayout>
    </div>
    <!-- <router-link :to="tabType.name"></router-link> -->
    <router-view v-slot="{ Component }">
      <transition name="home">
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </transition>
    </router-view>
    <footerLayout></footerLayout>
  </div>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import footerLayout from "./layout/footer.vue";
import headerLayout from "./layout/header.vue";
const listType = ref(0);
const tabValue = (val: number) => {
  listType.value = val;
};
</script>
<style lang="scss" scoped>
@media screen and (min-width: 768px) {
  .index-box {
    width: 100%;
    height: 100%;
    color: #282626;
    background-color: #fff;
    .header {
      width: 100%;
      height: 90px;
      z-index: 10000;
      position: relative;
      top: 0;
      left: 0;
    }
  }
}
@media screen and (max-width: 768px) {
  .index-box {
    width: 100%;
    height: 100%;
    color: #282626;
    background-color: #fff;
    .header {
      width: 100%;
      height: 5rem;
      z-index: 10000;
      position: relative;
      top: 0;
      left: 0;
    }
  }
}
</style>
