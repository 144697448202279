export default {
    Home: 'Home',
    member: 'chris membership',
    aboutUs: 'About us',
    services: 'our service',
    stores: 'branch address',
    More: "More",

    title: "The spot price of a cryptocurrency commodity",
    btnBuy: "Buy it now",
    serveTitle: "Cryptocurrency buying and selling services",
    serveText: "Chris - It is the cryptocurrency exchange store with the most branches in Hong Kong. It currently supports USDT, USDC, BTC and ETH transactions. Chris",
    serveTextcolor: "Not limited to exchanges and wallets",
    serveText1: ", and there is no minimum purchase amount. Customers only need to bring cash to the store, prepare a cryptocurrency wallet or exchange program, provide the payment address, and the transaction can be completed within a few minutes.",
    btnmore: "read more",
    currencyTitle: "Supported currencies",
    safeFast: "Safe and fast",
    lowBarrier: "Low entry barrier",
    freeConsul: "Free consultation",
    lowPremium: "Ultra low premium",
    descriptionText1: "    Face-to-face instant transactions",
    descriptionText2: "No restrictions on platforms and wallets",
    descriptionText3: "No minimum spend",
    descriptionText4: "guided teaching",
    descriptionText5: "Make it easy for beginners to get started",
    descriptionText6: "Stablecoin premiums are as low as",
    descriptionText7: "Non-stablecoin premiums are as low as",
    descriptionText8: "*Average charged by other Bitcoin providers",
    howBuyTitle: "How to buy cryptocurrency",
    howBuyText0: "Select the cryptocurrency for purchase",
    howBuyText1: "Confirm exchange rate",
    howBuyText2: "2-1. Go to the store to check the actual price",
    howBuyText3: "2-2. View a daily quote",
    howBuyText4: "2-3. WhatsApp",
    howBuyText5: "Quotation (office hours)",
    howBuyText6: "Pay cash",
    howBuyText7: "Have your cryptocurrency address scanned by a store clerk",
    howBuyText8: "Receive a receipt confirming the amount and address",
    howBuyText9: "Blockchain starts transferring cryptocurrency",
    howBuyText10: "Get cryptocurrency, purchase completed!",

    howSellTitle: "How to sell cryptocurrency",
    howSellText0: "Select the cryptocurrency for sale",
    howSellText1: "Confirm exchange rate",
    howSellText2: "2-1. Go to the store to check the actual price",
    howSellText3: "2-2. View a daily quote",
    howSellText4: "2-3. WhatsApp",
    howSellText5: "Quotation (office hours)",
    howSellText6: "Have a clerk scan your cryptocurrency address",
    howSellText7: "Blockchain starts transmitting cryptocurrency",
    howSellText8: "Receive a receipt confirming the amount and address",
    howSellText9: "Password transfer successful",
    howSellText10: "Collect cash, sale completed!",

    problemTitle: "common problem",
    problemTitle0: "How can I buy cryptocurrency?",
    problemTitle1: "How to choose/install a cryptocurrency wallet?",
    problemTitle2: "What payment methods do you accept?",
    problemText0: "During a face-to-face transaction, you hand us cash. After confirming the transaction amount, we will send the cryptocurrency to your payment address and you will receive a receipt.",
    problemText1: "Cryptocurrency wallets are divided into hot wallets and cold wallets. Most people in the market use hot wallets. Different wallets are managed and operated by different companies, and customers should choose reputable wallets.",
    problemText2: "One store only accepts cash in Hong Kong dollars.",
    problembtn: "More FAQs",

    mapBranchBtn: "Detailed landmark of the store",
    mapBranchTitle: "Our branches",
    mapBranchText0: "Mongkok",
    mapBranchText1: "Causeway Bay",
    mapBranchText2: "Kwun Tong",
    mapBranchText3: "Tsim Sha Tsui",

    partnerTitle1: "Partner",
    partnerTitle2: "Contact us now to learn more",
    partnerText1: "An official customer service/complaint and WhatsApp",
    partnerText2: "A case of social media",

    menubarTitle: "Terms and Conditions",
    menubarText: "Chris Member Card membership program is operated by Chris Trading (Hong Kong) Litimed (hereinafter referred to as 'Chris') and is subject to the following terms and conditions. This program is only applicable to Chris physical branches and designated cooperative merchants in the Hong Kong Special Administrative Region. By applying to become a member of the Chris Member Card membership program, you indicate that you have read these terms and conditions, accepted and agreed to be bound by them. You also agree to provide Chris with your personal data ('Personal Data' within the meaning of the Personal Data (Privacy) Ordinance, Chapter 486, Laws of Hong Kong) and agree that Chris may collect and use it in accordance with its Personal Data (Privacy) Policy Statement Your personal data. ",
    menubarTitle1: "Definition",
    menubarText11: "'Membership Card' refers to the membership card issued to you after successfully applying for the Chris Member Card membership program to enjoy relevant rewards and offers. ",
    menubarText12: "'Validity period' refers to the 12 months from the effective date of membership or upgrade or renewal",
    menubarText13: "'Branch' refers to the physical retail store operated by Chris in Hong Kong",
    menubarText14: "'Accumulated spending' refers to the actual transaction amount paid by members for every HK$1 spent at Chris stores, minus the net transaction value of any cash coupons, any gift certificates or any discounts. ",
    menubarTitle2: "Membership Validity Period",
    menubarText21: "Chris Member Card membership is valid for one year. Anyone who accumulates net spending of HK$30,000 or more during the membership period will automatically retain and renew his Chris Member Card membership to the next year. The validity period is Refers to the 12 months from the membership’s effective date or upgrade date or renewal date.",
    menubarText22: "All memberships will be automatically revoked when the validity period expires.",
    menubarTitle3: "Membership usage and membership fees",
    menubarText31: "To exercise membership rights and member-related transactions, including membership discounts, accumulation of points, exchanges and refunds, etc., the membership card must be presented for system records.",
    menubarText32: "The designated membership card issued to each member can only be used by the same person, and can only have one membership card at the same time. The membership card cannot be transferred to others. If it is found to be abused or used improperly, Chris reserves the right to terminate it. or the right to suspend membership.",
    menubarText33: "Only one membership card can be accepted for discount per transaction, and it cannot be combined with other discounts and used at the same time.",
    menubarText34: "If the Chris member's physical membership card is lost and a replacement physical membership card is required, a handling fee of HKD 100 will be paid. The relevant fee is non-refundable.",
    menubarText35: "Members must keep and use their membership cards properly. Chris is not responsible for any losses caused by theft or loss of membership cards.",
    menubarTitle4: "Member discounts and discounts",
    menubarText41: "To exercise membership rights and member-related transactions, including membership discounts, accumulated points, exchanges and refunds, etc., you must present your membership card for system records and your ID card for identity verification.",
    menubarText42: "The designated membership card issued to each member can only be used by the same person. The membership card cannot be transferred to others. If abuse or improper use is found, Chris reserves the right to terminate or suspend the membership.",
    menubarText43: "Only one membership card can be accepted for discount per transaction, and it cannot be combined with other discounts and used at the same time.",
    menubarText44: "If the Chris member's physical membership card is lost and a replacement physical membership card is required, a handling fee of HKD 100 will be paid. The relevant fee is non-refundable.",
    menubarText45: "Members must keep and use their membership cards properly. Chris is not responsible for any losses caused by theft or loss of membership cards.",
    menubarText46: "When you present your Chris Member Card at Chris stores, you can enjoy zero premium discount for the first thousand Hong Kong dollars purchased in non-stable coins every month, and you can enjoy corresponding premium discounts for non-stable coins. At the same time, present your Chris Member Card to Chris partner merchants Membership cards can enjoy specific discounts, and the content and duration of the discounts are determined by the cooperative merchant.",
    menubarText47: "Chris Member Card members who successfully recommend a third party to open a card will receive HKD 50 equivalent in Bitcoin.",
    menubarTitle5: "Membership Application",
    menubarText51: "Member registration methods include (1) all physical branches of Chris and (2) specific online channels or web pages of Chris.",
    menubarText52: "When applying to become a Chris Member Card member (including all membership levels, hereinafter referred to as 'members'), there is a membership application fee in Hong Kong dollars. The membership application fee may be adjusted by Chris from time to time without notice. Customers who want to join the membership plan should pay attention to the fees stated in Chris at that time. Before becoming a member, customers must (1) fill in the information and submit relevant required documentary evidence, (2) agree to these terms and conditions, (3) pay the relevant membership application fee in full. ",
    menubarText53: "Applicants must ensure that the member registration information provided is true, correct, complete, and not misleading or fraudulent.",
    menubarText54: "After successful application, the applicant will receive a physical membership card or an electronic membership card.",
    menubarText55: "In order to facilitate information about offers and promotions, you may be asked to supplement or provide us with complete and accurate personal information when registering as a member for the first time, including but not limited to birthday information, interests and contact addresses.",
    menubarText56: "To the extent permitted by law, any claims, demands, or liabilities arising from the omission, negligence, and/or failure of customers or members to provide us with their latest information, or the accuracy and completeness of such information. or litigation, the company will not bear any responsibility.",
    menubarTitle6: "General Terms",
    menubarText61: "Chris will send notifications about member discounts and promotions to members via emails and text messages. Members must provide correct email addresses and mobile phone numbers to avoid missing important notifications.",
    menubarText62: "Address and mobile phone number to avoid missing important notifications.",
    menubarText63: "Chris is not responsible for failed emails or text messages.",
    menubarText64: "Chris is not responsible for any losses caused by any computer technology or system problems.",
    menubarText65: "Chris has the absolute discretion to refuse and/or revoke any applicant's membership application for any reason, and terminate and/or cancel its membership. If the membership is terminated by us for any reason, all relevant records will also be Be logged out immediately.",
    menubarText66: "If a court decides that any provision of these Terms and Conditions is invalid or unenforceable, the remaining provisions will not be affected by the invalidity or unenforceability and will continue to be in full force and effect.",
    menubarText67: "These terms and conditions are governed by the laws of the Hong Kong Special Administrative Region, and members agree to be subject to the exclusive jurisdiction of the Hong Kong courts.",
    menubarText68: "Chris reserves the right to modify these terms and conditions at any time without prior notice.",
    menubarText69: "In case of any dispute, Chris reserves the right to make the final decision.",
    menubarText610: "Personal information collection statement regarding a membership card,",
    menubarText611: "Yes ",
    menubarText612: "Click here",
    menubarText613: " See .",

    aboutTitle: "About us",
    aboutTitle1: "Yizong Trading (Hong Kong) Co., Ltd.",
    aboutText1: "We are a company established and registered in Hong Kong, jointly promoting technological development and cryptocurrency applications.",
    aboutText2: "Through joint efforts to change the form of money, cryptocurrency will become a global currency in the new era to promote new economic activities of mankind in the fifth industrial revolution based on machine intelligence.",
    aboutText3: "Yizong believes",
    aboutText11: "1. Through",
    aboutText12: "Reduce market spread,",
    aboutText13: "Lowering the transaction threshold can improve the market liquidity of cryptocurrency.",
    aboutText21: "2. Increase consumer surplus,",
    aboutText22: "With appropriate education,",
    aboutText23: "It can increase the public's willingness to enter cryptocurrency.",
    aboutText31: "3. Over time, the market value of large cryptocurrencies will increase and their prices will gradually stabilize.",
    aboutText41: "4. Non-professional investors and non-institutional investors are qualified to invest in a healthy cryptocurrency market.",
    aboutText51: "5. Cryptocurrency surges",
    aboutText52: "Reduced remittance costs and daily transaction costs",
    aboutText53: ",make",
    aboutText54: "Global trade can break down geographical barriers.",
    aboutText55: "It's not just a store of value.",
    aboutText61: "6. In this new virtual currency ecosystem, different stakeholders play important roles in building an innovative and beneficial global financial system.",
    aboutText71: "7. Individual peer-to-peer market makers and traders can join hands",
    aboutText72: "Shaping a healthier cryptocurrency market",
    aboutText73: "- As long as we work together, it’s absolutely possible!",
    aboutText0: "You can call or WhatsApps",
    aboutText01: " (+852) 9358 9886",
    aboutText02: "Contact us.",
    aboutBtn: "Contact Us",
    aboutTitle2: "Want to know more details? Contact us now",

    copyright: "©2023 CHRIS TRADING CO., LIMITED",

}