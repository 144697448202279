import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import index from '../views/cryptocurrency/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    component: index,
    children: [
      {
        path: "/",
        name: "home",
        component: () => import(/* webpackChunkName: "dashboard" */ "../views/cryptocurrency/pages/home.vue"),
        meta: {
          title: "这是动态title",
          content: 'disable',
        }
      },
      {
        path: "/member",
        name: "member",
        component: () => import(/* webpackChunkName: "dashboard" */ "../views/cryptocurrency/pages/member.vue"),
      },
      {
        path: "/aboutUs",
        name: "aboutUs",
        component: () => import(/* webpackChunkName: "dashboard" */ "../views/cryptocurrency/pages/aboutUs.vue"),
      },
      {
        path: "/missions",
        name: "missions",
        component: () => import(/* webpackChunkName: "dashboard" */ "../views/cryptocurrency/pages/missions.vue"),
      },
      {
        path: "/services",
        name: "services",
        component: () => import(/* webpackChunkName: "dashboard" */ "../views/cryptocurrency/pages/services.vue"),
      },
      {
        path: "/stores",
        name: "stores",
        component: () => import(/* webpackChunkName: "dashboard" */ "../views/cryptocurrency/pages/stores.vue"),
      },
      {
        path: "/faq",
        name: "faq",
        component: () => import(/* webpackChunkName: "dashboard" */ "../views/cryptocurrency/pages/faq.vue"),
      },
      {
        path: "/coolwallet",
        name: "coolwallet",
        component: () => import(/* webpackChunkName: "dashboard" */ "../views/cryptocurrency/pages/coolwallet.vue"),
      },
      {
        path: "/career",
        name: "career",
        component: () => import(/* webpackChunkName: "dashboard" */ "../views/cryptocurrency/pages/career.vue"),
      },
      {
        path: "/franchisee",
        name: "franchisee",
        component: () => import(/* webpackChunkName: "dashboard" */ "../views/cryptocurrency/pages/franchisee.vue"),
      },
      {
        path: "/scamCorner",
        name: "scamCorner",
        component: () => import(/* webpackChunkName: "dashboard" */ "../views/cryptocurrency/pages/scamCorner.vue"),
      },
      {
        path: "/aml",
        name: "aml",
        component: () => import(/* webpackChunkName: "dashboard" */ "../views/cryptocurrency/pages/aml.vue"),
      },
      {
        path: "/terms",
        name: "terms",
        component: () => import(/* webpackChunkName: "dashboard" */ "../views/cryptocurrency/pages/terms.vue"),
      },
      {
        path: "/contact",
        name: "contact",
        component: () => import(/* webpackChunkName: "dashboard" */ "../views/cryptocurrency/pages/contact.vue"),
      },
      {
        path: "/tutorial",
        name: "tutorial",
        component: () => import(/* webpackChunkName: "dashboard" */ "../views/cryptocurrency/pages/tutorial.vue"),
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = "Chris trading";
  next();
});

export default router
