export default {
    Home: '首页',
    member: '克里斯会员',
    aboutUs: '关于我们',
    services: '我们的服务',
    stores: '分店地址',
    missions:'我们的使命',

    title: "克里斯加密货币商品现货价格",
    btnBuy: "立即购买",
    serveTitle: "加密货币买卖服务",
    serveText: "Chris 克里斯加密货币兑换店 - 在香港拥有众多分店，现支持USDT、USDC、BTC及ETH买卖。克里斯",
    serveTextcolor: "不限交易所及钱包",
    serveText1: "，更不设最低消费额。客人只需携带现金到门店，准备好加密货币钱包或交易所程式，提供收款地址，几分钟内便可完成交易。",
    btnmore: "阅读更多",
    currencyTitle: "支持币种",
    safeFast: "安全快捷",
    lowBarrier: "低入场门槛",
    freeConsul: "免费咨询",
    lowPremium: "超低溢价",
    descriptionText1: "面对面即时交易 ",
    descriptionText2: "不限平台及钱包",
    descriptionText3: "不设最低消费",
    descriptionText4: "指导式教学 ",
    descriptionText5: "让初学者轻松上手",
    descriptionText6: "稳定币溢价低至",
    descriptionText7: "非稳定币溢价低至 ",
    descriptionText8: "*其他比特币供应商平均收取",
    howBuyTitle: "如何购买加密货币",
    howBuyText0: "选定购买之加密货币",
    howBuyText1: "确认汇率",
    howBuyText2: "2-1.到门市查看实际牌价",
    howBuyText3: "2-2.查看克里斯每日报价道",
    howBuyText4: "2-3. WhatsApp",
    howBuyText5: "报价(办公时间内)",
    howBuyText6: "缴付现金",
    howBuyText7: "由店员扫描您的加密货币地址",
    howBuyText8: "获发收据确认金额及地址",
    howBuyText9: "区块链开始传送加密货币",
    howBuyText10: "取得加密货币，购买完成!",

    howSellTitle: "如何出售加密货币",
    howSellText0: "选定出售之加密货币",
    howSellText1: "确认汇率",
    howSellText2: "2-1.到门市查看实际牌价",
    howSellText3: "2-2.查看克里斯每日报价道",
    howSellText4: "2-3. WhatsApp",
    howSellText5: "报价(办公时间内)",
    howSellText6: "由店员扫描您的加密货币地址",
    howSellText7: "区块链开始传送加密货币",
    howSellText8: "获发收据确认金额及地址",
    howSellText9: "加密货币传送成功",
    howSellText10: "收取现金，出售完成！",

    problemTitle: "常见问题",
    problemTitle0: "我怎样可以购买加密货币？",
    problemTitle1: "如何选择/安装加密货币钱包？",
    problemTitle2: "你们接受哪种付款方法？",
    problemText0: "在面对面的交易过程中，你交付我们现金，确定交易金额后，我们会把加密货币传送予你的收款地址，你将获发收据。",
    problemText1: "加密货币钱包分为热钱包及冷钱包。市场上大部分人均使用热钱包。不同钱包由不同公司管理及营运，顾客应选择有信誉的钱包。",
    problemText2: "克里斯门店只接受港币现金。",
    problembtn: "更多常见问题",

    mapBranchBtn: "店铺详细地标",
    mapBranchTitle: "我们的分店",
    mapBranchText0: "旺角店",
    mapBranchText1: "旺角东",
    mapBranchText2: "上水店",
    mapBranchText3: "元朗店",

    partnerTitle1: "合作伙伴",
    partnerTitle2: "立即联络我们了解更多",
    partnerText1: "克里斯官方客服/投诉 及 WhatsApp",
    partnerText2: "克里斯社交媒体",

    menubarTitle: "条款及细则",
    menubarText: "Chris Member Card 会员计划是由Chris Trading (Hong Kong) Litimed（下称「Chris」）所运作，并受以下条款及细则所约束。此计划只适用于香港特别行政区的會員計劃是由Chris实体分店及指定合作商户。透过申请成为Chris Member Card 会员计划之会员，即表示阁下已阅读本条款及细则，并接受及同意受其约束。阁下亦同意向會員計劃是由Chris提供阁下的个人资料（「个人资料」根据香港法例第486章《个人资料（私隐）条例》的释义）及同意會員計劃是由Chris可根据其个人资料（私隐）政策声明收集及使用阁下的个人资料。 ",
    menubarTitle1: "定义",
    menubarText11: "「会员卡」指在成功申请Chris Member Card 会员计划后向阁下发出的会员卡，用以获享相关奖赏优惠。 ",
    menubarText12: "「有效期」是指会籍的生效日期或升级或续会起计12个月",
    menubarText13: "「分店」指CHRIS在香港经营的实体零售商铺",
    menubarText14: "「累积消费」指会员于CHRIS分店每消费HK$1时实际须付之交易金额扣除以任何现金券、任何礼券或任何折扣优惠的交易净值。 ",
    menubarTitle2: "会籍有效期",
    menubarText21: "Chris Member Card 会籍的有效期为一年。凡于会籍有效期间累积消费净值满港币30000元或以上，其Chris Member Card 会籍将会被自动保留并更新至下一年度。有效期是指会籍的生效日期或升级日期或续会日期起计的12个月。",
    menubarText22: "所有会籍将会在有效期届满时自动撤回。",
    menubarTitle3: "会籍使用及会员费用",
    menubarText31: "行使会员权利及有关会员之交易，包括以会员折扣、累积积分、换货及退款等，均须出示会员卡以作系统记录。",
    menubarText32: "每位会员所获发的指定会员卡只可同一人使用，同时只能拥有一张会员卡。会员卡是不可转让他人使用，如发现有滥用或不正当使用的情况，CHRIS保留终止或暂停会籍的权利。",
    menubarText33: "每次交易只可接受一张会员卡作折扣，而且不能合并其他优惠同时使用。",
    menubarText34: "如CHRIS会员实体会员卡遗失并需补领实体会员卡，须缴付港币 100 元手续费，有关费用将不可退还。",
    menubarText35: "会员须妥善保管及使用其会员卡，CHRIS恕不负责任何因被盗用或遗失会员卡而导致的损失。",
    menubarTitle4: "会员优惠及折扣",
    menubarText41: "行使会员权利及有关会员之交易，包括以会员折扣、累积积分、换货及退款等，均须出示会员卡以作系统记录及出示身份证认证身份。",
    menubarText42: "每位会员所获发的指定会员卡只可同一人使用。会员卡是不可转让他人使用，如发现有滥用或不正当使用的情况，CHRIS保留终止或暂停会籍的权利。",
    menubarText43: "每次交易只可接受一张会员卡作折扣，而且不能合并其他优惠同时使用。",
    menubarText44: "如CHRIS会员实体会员卡遗失并需补领实体会员卡，须缴付港币 100 元手续费，有关费用将不可退还。",
    menubarText45: "会员须妥善保管及使用其会员卡，CHRIS恕不负责任何因被盗用或遗失会员卡而导致的损失。",
    menubarText46: "凡于CHRIS门市出示Chris Member Card 会员卡，每月首一千港币买非稳定币可享零溢价优惠、非稳定币均可享相应的溢价优惠。同时向CHRIS合作商户出示Chris Member Card会员卡可享特定优惠，优惠内容及优惠时效由该合作商户决定。",
    menubarText47: "Chris Member Card 会员成功推荐第三方开卡可获50港元等值之比特币。",
    menubarTitle5: "会籍申请",
    menubarText51: "会员的登记途径包括 (1) CHRIS所有实体分店 及 (2) CHRIS特定的网上渠道或网页。",
    menubarText52: "申请成为Chris Member Card 会员（包含所有会籍级别，下称「会员」）时，设港币入会申请费用。入会申请费用或将由CHRIS在未有通知的情况下作不时调整，欲加入会员计划的客户需留意当时 CHRIS 所载的费用。成为会员前，客户须 (1) 填写资料并提交相关要求之文件证明、(2) 同意本条款及细则、 (3) 全数缴付相关的入会申请费用。 ",
    menubarText53: "申请人必须确保所提供的会员登记资料全属真实、正确、完整、没有误导及欺诈成份。",
    menubarText54: "成功申请后，申请人会获得实体会员卡一张或电子会员卡会借。",
    menubarText55: "为方便获悉优惠及推广活动资讯，在首次登记会员时可能会被要求向我们补充或提供完整及准确的个人资料，包括但不限于生日资料、兴趣及联络地址等。",
    menubarText56: "在法律许可的范围内，因顾客或会员的遗漏、疏忽及或未能向我们提供其最新的资料，或因该等资料的准确性及完整性所引致的任何索偿、要求、责任或诉讼，本公司将不会承担任何责任。",
    menubarTitle6: "一般条款",
    menubarText61: "CHRIS会透过电邮及短讯向会员发送会员优惠及推广的通知。会员须提供正确的电邮地址及手机号码，以免错失重要通知。",
    menubarText62: "地址及手机号码，以免错失重要通知。",
    menubarText63: "CHRIS对未能成功传送的电邮或手机短讯概不负责。",
    menubarText64: "因任何电脑技术或系统问题而造成的损失，CHRIS恕不负责。",
    menubarText65: "CHRIS有绝对的酌情权以任何理由拒绝及/或撤销任何申请者的会籍申请，及终止及/或取消其会籍。若会籍因任何原因被我们终止，所有相关纪录亦会被立即注销。",
    menubarText66: "如法院裁定本条款及细则内任何条款无效或不可强制执行，其余条款将不受上述无效或不可强制执行影响，并将继续具十足效力及作用。",
    menubarText67: "本条款及细则受香港特别行政区的法律管限，而会员均同意受香港法院的专有审判权所限。",
    menubarText68: "CHRIS保留随时在不另行通知的情况下修改本条款及细则的权利。",
    menubarText69: "如有任何争议，CHRIS保留最终决定权。",
    menubarText610: "关于克里斯会员卡的个人资料收集声明，",
    menubarText611: "可 ",
    menubarText612: "按此",
    menubarText613: " 参阅 。",

    aboutTitle: "关于我们",
    aboutTitle1: "克里斯贸易（香港）有限公司",
    aboutText1: "我们是一间香港成立及注册的公司，共同推动科技发展及加密货币应用。",
    aboutText2: "透过共同努力改变金钱的形态，使加密货币成为新纪元的全球货币，以促进人类在机器智慧为本的第五次工业革命中的新经济活动。",
    aboutText3: "克里斯相信",
    aboutText11: "1. 透过",
    aboutText12: "减少市场价差，",
    aboutText13: "降低交易门槛，可提高加密货币的市场流动性。",
    aboutText21: "2. 增加消费者盈余，",
    aboutText22: "配合适当的教育，",
    aboutText23: "可提高大众对加密货币的入场意欲。",
    aboutText31: "3. 随着时间发展，大型加密货币的市值将会提高，其价格亦会逐渐稳定下来。",
    aboutText41: "4. 非专业投资者及非机构性投资者有资格在一个健康加密货币市场中投资。",
    aboutText51: "5. 加密货币大幅",
    aboutText52: "减低了汇款成本及日常交易成本",
    aboutText53: "，使",
    aboutText54: "全球贸易可打破地域障碍。",
    aboutText55: "它不仅是一个价值储存工具。",
    aboutText61: "6. 在这个新的虚拟货币生态中，不同持份者都扮演着重要的角色，一同建立一套创新及对人类有益的全球金融系统。",
    aboutText71: "7. 个人点对点的造市商及交易员可以携手",
    aboutText72: "塑造一个更健康的加密货币市场",
    aboutText73: "– 只要我们一起努力是绝对可以的！",
    aboutText0: "你可以透过电话 或 WhatsApps",
    aboutText01: " (+852) 9358 9886",
    aboutText02: " 联络我们。",
    aboutBtn: "联络我们",
    aboutTitle2: "想了解更多详情？立即联络我们",

    copyright: "©2023 由克里斯贸易（香港） 有限公司全权拥有",


}