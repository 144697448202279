<template>
  <div>
    {{ $t("copyright") }}
  </div>
</template>
<script lang="ts" setup></script>
<style lang="scss" scoped>
@media screen and (min-width: 768px) {
  div {
    width: 100%;
    height: 64px;
    padding-bottom: 10px;
    font-size: 12px;
    display: flex;
    align-items: flex-end;
    box-sizing: border-box;
    justify-content: center;
  }
}
@media screen and (max-width: 768px) {
  div {
    width: 100%;
    height: 4rem;
    padding-bottom: .625rem;
    font-size: 1rem;
    display: flex;
    align-items: flex-end;
    box-sizing: border-box;
    justify-content: center;
  }
}
</style>
