export default {
    Home: '首頁',
    member: '克裏斯會員',
    aboutUs: '關於我們',
    services: '我們的服務',
    stores: '分店地址',
    missions:'我們的使命',

    faq: "常見問題",
    coolwallet: "CoolWallet",
    career: "職位空缺",
    franchisee: "投資及聯營招商",
    scamCorner: "​預防騙案",
    aml: "身份認證/AML/CFT",
    terms: "​交易的條款及細則（香港）",
    contact: "聯絡我們",

    title: "克裏斯加密貨幣商品現貨價格",
    btnBuy: "立即購買",
    serveTitle: "加密貨幣買賣服務",
    serveText: "Chris 克里斯加密貨幣兌換店 - 在香港擁有眾多分店，現支援USDT、USDC、BTC及ETH買賣。 克里斯",
    serveTextcolor: "不限交易所及錢包",
    serveText1: "，更不設最低消費額。客人只需攜帶現金到門店，準備好加密貨幣錢包或交易所程式，提供收款地址，幾分鐘內便可完成交易。",
    btnmore: "閱讀更多",
    currencyTitle: "支援幣種",
    safeFast: "安全快捷",
    lowBarrier: "低入場門檻",
    freeConsul: "免費諮詢",
    lowPremium: "超低溢價",

    descriptionText1: "面對面即時交易 ",
    descriptionText2: "不限平台及錢包",
    descriptionText3: "不設最低消費",
    descriptionText4: "指導式教學 ",
    descriptionText5: "讓初學者輕鬆上手",
    descriptionText6: "穩定幣溢價低至",
    descriptionText7: "非穩定幣溢價低至 ",
    descriptionText8: "*其他比特幣供應商平均收取",

    howBuyTitle: "如何購買加密貨幣",
    howBuyText0: "選定購買之加密貨幣",
    howBuyText1: "確認匯率",
    howBuyText2: "2-1.到門市查看實際牌價",
    howBuyText3: "2-2.查看克裏斯每日報價道",
    howBuyText4: "2-3. WhatsApp",
    howBuyText5: "報價(辦公時間內)",
    howBuyText6: "繳付現金",
    howBuyText7: "由店員掃描您的加密貨幣地址",
    howBuyText8: "獲發收據確認金額及地址",
    howBuyText9: "區塊鏈開始傳送加密貨幣",
    howBuyText10: "取得加密貨幣，購買完成!",

    howSellTitle: "如何出售加密貨幣",
    howSellText0: "選定出售之加密貨幣",
    howSellText1: "確認匯率",
    howSellText2: "2-1.到門市查看實際牌價",
    howSellText3: "2-2.查看克裏斯每日報價道",
    howSellText4: "2-3. WhatsApp",
    howSellText5: "報價(辦公時間內)",
    howSellText6: "由店員掃描您的加密貨幣地址",
    howSellText7: "區塊鏈開始傳送加密貨幣",
    howSellText8: "獲發收據確認金額及地址",
    howSellText9: "加密貨幣傳送成功",
    howSellText10: "收取現金，出售完成！",

    problemTitle: "常見問題",
    problemTitle0: "我怎樣可以購買加密貨幣？",
    problemTitle1: "如何選擇/安裝加密貨幣錢包？",
    problemTitle2: "你們接受哪種付款方法？",
    problemText0: "在面對面的交易過程中，你交付我們現金，確定交易金額後，我們會把加密貨幣傳送予你的收款地址，你將獲發收據。",
    problemText1: "加密貨幣錢包分為熱錢包及冷錢包。市場上大部分人均使用熱錢包。不同錢包由不同公司管理及營運，顧客應選擇有信譽的錢包。",
    problemText2: "克裏斯門店只接受港幣現金。",
    problembtn: "更多常見問題",

    mapBranchBtn: "店舖詳細地標",
    mapBranchTitle: "我們的分店",
    mapBranchText0: "旺角",
    mapBranchText1: "銅鑼灣",
    mapBranchText2: "觀塘",
    mapBranchText3: "尖沙咀",

    partnerTitle1: "合作夥伴",
    partnerTitle2: "立即聯絡我們了解更多",
    partnerText1: "克裏斯官方客服/投訴 及 WhatsApp",
    partnerText2: "克裏斯社交媒體",

    menubarTitle: "條款及細則",
    menubarText: "Chris Member Card 會員計劃是由Chris Trading (Hong Kong) Litimed（下稱「會員計劃是由Chris」）所運作，並受以下條款及細則所約束。此計劃只適用於香港特別行政區的會員計劃是由Chris實體分店及指定合作商戶。透過申請成為Chris Member Card 會員計劃之會員，即表示閣下已閱讀本條款及細則，並接受及同意受其約束。閣下亦同意向會員計劃是由Chris提供閣下的個人資料（「個人資料」根據香港法例第486章《個人資料（私隱）條例》的釋義）及同意會員計劃是由Chris可根據其個人資料（私隱）政策聲明收集及使用閣下的個人資料。",
    menubarTitle1: "定義",
    menubarText11: "「會員卡」指在成功申請Chris Member Card 會員計劃後向閣下發出的會員卡，用以獲享相關獎賞優惠。",
    menubarText12: "「有效期」是指會籍的生效日期或升級或續會起計12個月",
    menubarText13: "「分店」指會員計劃是由Chris在香港經營的實體零售商舖",
    menubarText14: "「累積消費」指會員於會員計劃是由Chris分店每消費HK$1時實際須付之交易金額扣除以任何現金券、任何禮券或任何折扣優惠的交易淨值。",
    menubarTitle2: "會籍有效期",
    menubarText21: "Chris Member Card 會籍的有效期為一年。凡於會籍有效期間累積消費淨值滿港幣30000元或以上，其Chris Member Card 會籍將會被自動保留並更新至下一年度。有效期是指會籍的生效日期或升級日期或續會日期起計的12個月。",
    menubarText22: "所有會籍將會在有效期屆滿時自動撤回。",
    menubarTitle3: "會籍使用及會員費用",
    menubarText31: "行使會員權利及有關會員之交易，包括以會員折扣、累積積分、換貨及退款等，均須出示會員卡以作系統記錄。",
    menubarText32: "每位會員所獲發的指定會員卡只可同一人使用，同時只能擁有一張會員卡。會員卡是不可轉讓他人使用，如發現有濫用或不正當使用的情況，會員計劃是由Chris保留終止或暫停會籍的權利。",
    menubarText33: "每次交易只可接受一張會員卡作折扣，而且不能合併其他優惠同時使用。",
    menubarText34: "如會員計劃是由Chris會員實體會員卡遺失並需補領實體會員卡，須繳付港幣 100 元手續費，有關費用將不可退還。",
    menubarText35: "會員須妥善保管及使用其會員卡，會員計劃是由Chris恕不負責任何因被盜用或遺失會員卡而導致的損失。",
    menubarTitle4: "會員優惠及折扣",
    menubarText41: "行使會員權利及有關會員之交易，包括以會員折扣、累積積分、換貨及退款等，均須出示會員卡以作系統記錄及出示身份證認証身份。",
    menubarText42: "每位會員所獲發的指定會員卡只可同一人使用。會員卡是不可轉讓他人使用，如發現有濫用或不正當使用的情況，會員計劃是由Chris保留終止或暫停會籍的權利。",
    menubarText43: "每次交易只可接受一張會員卡作折扣，而且不能合併其他優惠同時使用。",
    menubarText44: "如會員計劃是由Chris會員實體會員卡遺失並需補領實體會員卡，須繳付港幣 100 元手續費，有關費用將不可退還。",
    menubarText45: "會員須妥善保管及使用其會員卡，會員計劃是由Chris恕不負責任何因被盜用或遺失會員卡而導致的損失。",
    menubarText46: "凡於會員計劃是由Chris門市出示Chris Member Card 會員卡，每月首一千港幣買非穩定幣可享零溢價優惠、非穩定幣均可享相應的溢價優惠。同時向會員計劃是由Chris合作商戶出示Chris Member Card 會員卡可享特定優惠，優惠內容及優惠時效由該合作商戶決定。",
    menubarText47: "Chris Member Card 會員成功推薦第三方開卡可獲50港元等值之比特幣。",
    menubarTitle5: "會籍申請",
    menubarText51: "會員的登記途徑包括 (1) 會員計劃是由Chris所有實體分店 及 (2) 會員計劃是由Chris特定的網上渠道或網頁。",
    menubarText52: "申請成為Chris Member Card 會員（包含所有會籍級別，下稱「會員」）時，設港幣入會申請費用。入會申請費用或將由會員計劃是由Chris在未有通知的情況下作不時調整，欲加入會員計劃的客戶需留意當時 會員計劃是由Chris 所載的費用。成為會員前，客戶須 (1) 填寫資料並提交相關要求之文件證明、(2) 同意本條款及細則、 (3) 全數繳付相關的入會申請費用。",
    menubarText53: "申請人必須確保所提供的會員登記資料全屬真實、正確、完整、沒有誤導及欺詐成份。",
    menubarText54: "成功申請後，申請人會獲得實體會員卡一張或電子會員卡會藉。",
    menubarText55: "為方便獲悉優惠及推廣活動資訊，在首次登記會員時可能會被要求向我們補充或提供完整及準確的個人資料，包括但不限於生日資料、興趣及聯絡地址等。",
    menubarText56: "在法律許可的範圍內，因顧客或會員的遺漏、疏忽及或未能向我們提供其最新的資料，或因該等資料的準確性及完整性所引致的任何索償、要求、責任或訴訟，本公司將不會承擔任何責任。",
    menubarTitle6: "一般條款",
    menubarText61: "會員計劃是由Chris會透過電郵及短訊向會員發送會員優惠及推廣的通知。會員須提供正確的電郵地址及手機號碼，以免錯失重要通知。",
    menubarText62: "會員計劃是由Chris對未能成功傳送的電郵或手機短訊概不負責。",
    menubarText63: "因任何電腦技術或系統問題而造成的損失，會員計劃是由Chris恕不負責。",
    menubarText64: "會員計劃是由Chris有絕對的酌情權以任何理由拒絕及/或撤銷任何申請者的會籍申請，及終止及/或取消其會籍。若會籍因任何原因被我們終止，所有相關紀錄亦會被立即註銷。",
    menubarText65: "如法院裁定本條款及細則內任何條款無效或不可強制執行，其餘條款將不受上述無效或不可強制執行影響，並將繼續具十足效力及作用。",
    menubarText66: "本條款及細則受香港特別行政區的法律管限，而會員均同意受香港法院的專有審判權所限。",
    menubarText67: "會員計劃是由Chris保留隨時在不另行通知的情況下修改本條款及細則的權利。",
    menubarText68: "如有任何爭議，會員計劃是由Chris保留最終決定權。",
    menubarText69: "關於克裏斯會員卡的個人資料收集聲明，",
    menubarText610: "可 ",
    menubarText611: "按此",
    menubarText612: " 參閱 。",

    aboutTitle: "關於我們",
    aboutTitle1: "克裏斯貿易（香港）有限公司",
    aboutText11: "我們是一間香港成立及註冊的公司，共同推動科技發展及加密貨幣應用。",
    aboutText12: "透過共同努力改變金錢的形態，使加密貨幣成為新紀元的全球貨幣，以促進人類在機器智慧為本的第五次工業革命中的新經濟活動。",
    aboutText13: "克裏斯相信",
    aboutText1: "1. 透過減少市場價差，降低交易門檻，可提高加密貨幣的市場流動性。",
    aboutText2: "2. 增加消費者盈餘，配合適當的教育，可提高大眾對加密貨幣的入場意欲。",
    aboutText3: "3. 隨著時間發展，大型加密貨幣的市值將會提高，其價格亦會逐漸穩定下來。",
    aboutText4: "4. 非專業投資者及非機構性投資者有資格在一個健康加密貨幣市場中投資。",
    aboutText5: "5. 加密貨幣大幅減低了匯款成本及日常交易成本，使全球貿易可打破地域障礙。它不僅是一個價值儲存工具。",
    aboutText6: "6. 在這個新的虛擬貨幣生態中，不同持份者都扮演著重要的角色，一同建立一套創新及對人類有益的全球金融系統。",
    aboutText7: "7. 個人點對點的造市商及交易員可以攜手塑造一個更健康的加密貨幣市場– 只要我們一起努力是絕對可以的！",
    aboutTitle2: "想了解更多詳情？立即聯絡我們",
    aboutText0: "你可以透過電話 或 WhatsApps",
    aboutText01: " (+852) 9358 9886",
    aboutText02: " 聯絡我們。",
    aboutBtn: "聯絡我們",

    missionsTitle: "我們的使命",
    missionsLi1: "1. 將沿用已久的舊法定貨幣體系順利過渡至以加密貨幣為本的新金融體系",
    missionsLi2: "2. 降低大眾購買加密貨幣的成本",
    missionsLi3: "3. 協助普羅大眾購買加密貨幣，將加密貨幣注入至現在的金融系統中",
    missionsText1: "整個全球化貨幣有序過渡計劃有多個不同階段。",
    missionsText2: "首階段要使普羅大眾擁有買賣加密貨幣的機會，即大眾能以法定貨幣兌換加密貨幣。",
    missionsText3: "克裏斯希望大眾能夠以最低廉的市場價格就可以購買到比特幣。",
    missionsText4: "在不久的將來，人們會先在電子錢包中存放比特幣作為儲備，隨著整個虛擬貨幣全球化體系逐漸成熟發展，預計未來社會將使用新的加密貨幣作付款媒介。",
    missionsText5: "全球貿易以頻繁交易、無地理限制、新科技行業為本，令加密貨幣發揮著其獨特性。",
    missionsText6: "屆時整套全自動且數位化的新體系將具備智能合約、去中心化金融等特點，圍繞各種嶄新科技包括人工智能、大數據、5G、物聯網、雲端計算。",

    servicesTitle: "我們的服務",
    servicesTitle1: "教程及諮詢",
    servicesText1: "初學者可獲得免費教程",
    servicesText2: "或參與主題講座",
    servicesTitle2: "現貨交易",
    servicesText3: "門店即時買賣加密貨幣",
    servicesTitle3: "購買冷錢包",
    servicesText4: "從我們的門店購買",
    servicesText5: "CoolWallet S / Pro 冷錢包",
    servicesTitle4: "想了解詳情？立即聯絡我們",
    servicesText6: "您可以通過電話及Whatsapp (852) 9358 9886、電子郵件或社交媒體渠道聯絡我們。",
    servicesBtn: "聯絡我們",

    storesTitle: "門店資料",
    storesText: "彌敦道 608 號",
    storesText1: "總統商業大廈2樓233鋪",
    storesText2: "營業時間：(一至日) 11:00 - 21:00",
    storesText3: "公眾假期照常營業",
    storesText4: "電話：",
    storesText5: "(+852) 9358 9886",
    storesTitle0: "旺角",
    storesTitle1: "銅鑼灣",
    storesTitle2: "觀塘",
    storesTitle3: "尖沙咀",
    storesTitle4: "九龍灣",
    storesTitle5: "上水",
    storesTitle6: "荔枝角",
    storesTitle7: "九龍塘",
    storesTitle8: "荃灣",

    faqtitle: "常見問題",
    faqtitle1: "一般問題",
    faqtitle2: "交易問題",
    faqLi1: "1. 比特幣到底是什麼？",
    faqLi2: "2. 如何選擇/安裝加密貨幣錢包？",
    faqLi3: "3. 誰決定比特幣的價格？",
    faqLi4: "4. 為什麼加密貨幣領域存在如此多的騙局和欺詐行為？  ",
    faqLi21: "1. 我如何購買加密貨幣？",
    faqLi22: "2. 我需要提供身份證明文件嗎？",
    faqLi23: "3. 我需時多久能收到加密貨幣？",
    faqLi24: "4. 比特幣後來漲價了怎麼賣？",
    faqLi25: "5. 你們接受什麼付款方式？",
    faqLi26: "6. 為什麼要選擇 Chris？",
    faqLi27: "7. 在香港購買加密貨幣是否合法？",
    faqLi28: "8. 需要在貴公司開戶嗎？",
    faqText11: "比特幣是下一個時代全球貨幣的候選者。",
    faqText12: "簡單來說，它的工作機制使用了一種名為區塊鏈的新技術，它利用加密學需要全世界的電腦一起工作來確認交易。",
    faqText13: "更多>>",
    faqText21: "加密錢包包括熱錢包和冷錢包，大多數人會使用熱錢包。不同的錢包由不同的公司營運，你應該選擇有聲譽較良好的。您可以查看我們官網上的錢包推薦列表和我們的錢包安裝教學。",
    faqText31: "市場決定比特幣的價格，它的價格每時每刻都在變化。人們在不同的加密貨幣交易所 24/7 全天候買賣比特幣，市場供求決定價格。",
    faqText41: "區塊鏈上的加密貨幣交易是不可逆的，客戶不應與身份不明的陌生人進行交易。犯罪分子通過各種編造的故事或投資計劃欺騙他們，誘騙受害者將他們的資產轉移到法定貨幣或加密貨幣中，當犯罪分子取得資產後，受害者便聯繫不到對方。",
    faqText1: "在面對面交易過程中，您支付現金並確認金額，我們便會將加密貨幣轉移到您的地址，您將獲發收據。",
    faqText2: "交易金額< 120,000 港元：無需身份驗證。",
    faqText3: "交易金額>＝ 120,000 港元：您需要提供您的香港身份證/有效護照，您可能需要提供地址證明（最近 2 個月）。",
    faqText4: "這取決於區塊鏈網絡，通常需要 5-20 分鐘才能完成交易。但如果發生區塊鏈擁塞，則可能需要幾個小時才能完成交易。",
    faqText5: "您可以將它們賣回給我們！或者您可以將它們出售給市場上的任何比特幣買家。",
    faqText6: "我們只接受現金。",
    faqText7: "我們的使命是為比特幣提供市場上最低的售價。與目前其他市場賣家相比，例如比特幣 ATM，您可能需要支付高達 10% 的費用，另外您可能需要支付發送費用。",
    faqText8: "此外，由於您與我們進行面對面交易，您將獲得我們的優質服務，您的所有問題都將得到解答。",
    faqText9: "是的，這是絕對合法的，只要您的資金是合法獲得的。對於監管和合規問題，您可以在此處瀏覽我們的政策。",
    faqText0: "不需要。加密貨幣將直接轉移到您的加密貨幣錢包或加密貨幣交易所，我們不會托管客人的加密貨幣。",


    coolwalletText: "CoolWallet 為庫幣科技自豪的產品線，是全球第一個專為行動裝置設計的硬體錢包，秉持最高安全標準守護用戶珍貴的加密貨幣資產。",
    coolwalletLi0: "- 信用卡大小",
    coolwalletLi1: "- 行動裝置適用 (Android & iOS)",
    coolwalletLi2: "- 加密藍牙連線",
    coolwalletLi3: "- EAL 5+ 認證安全晶片",
    coolwalletLi4: "- 支援多種加密貨幣",
    coolwalletLi5: "- 交易功能",
    coolwalletLi6: "- 應用程度整合 DeFi、 Dapp",
    coolwalletTitle0: "CoolWallet S",
    coolwalletText0: "​專為在日常生活需要兼顧便利性與安全性離線儲存裝置的加密貨幣玩家而設計",
    coolwalletLi10: "- 信用卡大小",
    coolwalletLi11: "- 行動裝置適用 (Android & iOS)",
    coolwalletLi12: "- 加密藍牙連線",
    coolwalletLi13: "- EAL 6+ 認證安全晶片",
    coolwalletLi14: "- 支援多種加密貨幣",
    coolwalletLi15: "- 交易功能",
    coolwalletLi16: "- 更多幣種",
    coolwalletLi17: "- 權益質押",
    coolwalletLi18: "- 應用程式整合 DeFi 及 Dapp",
    coolwalletLi19: "- 應用程式整合 NFT 交易平台",
    coolwalletTitle1: "CoolWallet Pro",
    coolwalletText1: "​適合期望享受更具安全性 DeFi/Staking 體驗，更高安全等級硬體及需要更多幣種支援的資深用戶",
    coolwalletText2: "如欲購買coolwallet 請到",
    coolwalleta1: "門市",
    coolwalletText3: "查詢",

    careerTitle: "職位空缺",
    careerTitle1: "加入我們",
    careerText: "克裏斯是一間香港成立及註冊的公司，共同推動科技發展及加密貨幣應用，在全港開設多間加密貨幣找換店，提供多種加密貨幣的現金即時買賣服務。克裏斯專注令加密貨幣普及化，降低市場門檻，讓普羅大眾更易接觸加密貨幣。透過共同努力改變金錢的形態，使加密貨幣成為新紀元的全球貨幣，以促進人類在機器智慧為本的第五次工業革命中的新經濟活動。",
    careerTitle2: "軟件工程師/開發人員",
    careerTitle3: "工作亮點",
    careerLi0: "擅長編寫程式",
    careerLi1: "有責任感",
    careerLi2: "對合適的候選人有吸引力的一攬子計劃",
    careerTitle4: "職位描述",
    careerLi41: "開發內部frontend及backend系統",
    careerLi42: "與團隊成員協調了解業務和客戶的需求",
    careerLi43: "建立健全的框架以促進不同系統組件的未來功能，包括對沖、交易、定價、記錄等系統。",
    careerLi44: "將來自第三方供應商/服務提供商的不同 API 整合到現有系統中",
    careerLi45: "協調並領導項目及/或向業務部門報告",
    careerTitle5: "職位需要",
    careerLi51: "後端編程語言：Python / Java / NodeJS / PHP / Ruby / Go 中的任何一種",
    careerLi52: "前端編程語言：JavaScript/React/AngularJS/Vue/TS/JQuery 等中的任何一種。",
    careerLi53: "數據庫和DBMS 知識：任何MySQL / Oracle / MongoDB",
    careerLi54: "實際項目經驗和動手能力是必須的。",
    careerLi55: "創業能力和心態，高度的責任感是必須的",
    careerLi56: "必須精通粵語；精通英語是加分項",
    careerLi57: "數學能力強且條理清晰者優先",
    careerLi58: "熱衷於加密貨幣或擁有加密貨幣基礎知識者優先",
    careerLi59: "願意學習新知識",
    careerTitle6: "我們提供什麼",
    careerLi61: "靈活的工作時間",
    careerLi62: "每週工作 5 天",
    careerLi63: "年尾花紅",
    careerLi64: "在職培訓",
    careerLi65: "快速擴張和支持性環境",
    careerLi66: "加密貨幣/區塊鏈領域的超級潛力",
    careerLi67: "膳食津貼和無限量小吃",
    careerLi68: "有醫療福利、病假及年假",
    careerText1: "請將您的履歷表和預期薪金發送至",
    careerTitle7: "加入我們的團隊",
    careerForm: "取得聯繫，以便我們開始合作。",

    franchiseeTitle: "聯營加盟",
    franchiseeTitle1: "克裏斯比特幣",
    franchiseeText: "克裏斯貿易(香港)有限公司是一間香港成立及註冊的公司，共同推動科技發展及加密貨幣應用，在全港開設多間加密貨幣找換店，提供多種加密貨幣的現金即時買賣服務。",
    franchiseeText1: "克裏斯專注令加密貨幣普及化，降低市場門檻，讓普羅大眾更易接觸加密貨幣。",
    franchiseeText2: "透過共同努力改變金錢的形態，使加密貨幣成為新紀元的全球貨幣，以促進人類在機器智慧為本的第五次工業革命中的新經濟活動。",
    franchiseeTitle2: "加密貨幣已成為世界趨勢",
    franchiseeText21: "用戶量快速增長：目前已有約2億人使用加密貨幣，未來預計將會逾10億人",
    franchiseeText22: "加密貨幣及支付方式已在各地融入日常生活",
    franchiseeText23: "投資者選擇加密貨幣作為投資及理財資產",
    franchiseeText24: "大多數機構投資者正在積極向客戶推薦加密資產",
    franchiseeText25: "全球多國企業已接受加密貨幣作為未來的支付方式",
    franchiseeTitle3: "OTC 業務介紹",
    franchiseeText31: "使用現有（或開設新店舖）以提供場地進行交易",
    franchiseeText32: "找換店模式：以低價購買，以高價賣出",
    franchiseeText33: "中間買賣差價即是生意利潤",
    franchiseeText34: "只需小量前期投資即可進入加密貨幣生意",
    franchiseeTitle4: "克裏斯的優勢",
    franchiseeTitle41: "全港最多分店",
    franchiseeText411: "集團經營，值得信賴",
    franchiseeText412: "提供廉價，穩定及可靠的加密貨幣供應",
    franchiseeTitle42: "品牌受用戶信賴",
    franchiseeText421: "社交網站專頁的觸及次數近 400,000 次 ",
    franchiseeText422: "Facebook 及 Instagram 新追蹤者人數平均每月上升超過 30% ",
    franchiseeText423: "參與本地及國際多個創科展覽，與用戶互動及推動普及化",
    franchiseeTitle43: "領先及完善的 IT 系統 ",
    franchiseeText431: "全自動化的聯營系統",
    franchiseeText432: "簡單易用及可靠的報價、對沖、庫存、發送及會計等紀錄系統",
    franchiseeTitle44: "加密貨幣教育",
    franchiseeText441: "提供加密貨幣教育講座",
    franchiseeText442: "提供職員入職訓練",
    franchiseeText443: "發放加密貨幣新聞",
    franchiseeTitle45: "標準化的程序，訓練及管理範本",
    franchiseeText451: "提供標準化的營運程序",
    franchiseeText452: "提供訓練及管理工具及範本",
    franchiseeTitle46: "​低投入，高回報",
    franchiseeText461: "根據歷史數據，特許經營兩年內接近9成月份均有淨利",
    franchiseeText462: "在分拆利潤後，特許經營商平均獲得每年20% 的回報",
    franchiseeText463: "成為特許經營商後，將獲得克裏斯其他合作項目的優先參與權",
    franchiseeTitle5: "加盟資格",
    franchiseeTitle51: "個人資格",
    franchiseeText51: "擁有香港永久居留權並年滿21歲",
    franchiseeText52: "有志創業，對加密貨幣有興趣人士",
    franchiseeText53: "沒有同時經營有競爭性或相關的業務",
    franchiseeText54: "並能接受市場競爭及勇於面對挑戰",
    franchiseeText55: "具找換或服務性行業經驗者優先考慮",
    franchiseeText56: "中學或以上程度學歷",
    franchiseeText57: "具備基本電腦知識應用及數學邏輯",
    franchiseeTitle52: "投資準備",
    franchiseeText58: "持有約 40 萬港元至 120 萬港元作為特許經營資金",
    franchiseeTitle6: "加盟流程",
    franchiseeLink: "按此聯絡了解更多/申請加盟",


    scamCornerTitle: "預防騙案",
    scamCornerTitle1: "小心誤墮加密貨幣騙局！",
    scamCornerTitle2: "騙案手法層出不窮，學習如何防範騙局！",
    scamCornerText: "- 僅從安全渠道（如 Apps Store 或 Google Play）下載錢包及應用程式。",
    scamCornerText1: "- 格外小心當：",
    scamCornerLi1: "陌生人在網絡上主動接觸你",
    scamCornerLi2: "有人向你兜售投資計劃或投資貼士",
    scamCornerLi3: "有人向你發送顯示巨額盈利的照片",
    scamCornerLi4: "有人要求你購買加密貨幣，因為你的包裹已被扣押",
    scamCornerLi5: "有人要求你購買加密貨幣，因為你牽涉某些刑事罪案",
    scamCornerText2: "- 香港並沒有徵收任何加密貨幣稅項。",
    scamCornerText3: "你可以使用下列的「防詐騙問卷」以協助你了解自己誤墮騙局的風險。",
    scamCornerLi6: "防詐騙問卷",
    scamCornerLink:"(pdf)",
    scamCornerLi7: "防詐騙問卷",
    scamCornerLink1:"(Google Form)",
    scamCornerText4: "你可以使用「防騙視伏器」以查看牽涉騙案的電話號碼、銀行戶口號碼等，此網站由香港警方開發。",
    scamCornerLi8: "「防騙視伏器」",
    scamCornerText5: "你可以向「反詐騙協調中心」報警，「反詐騙協調中心」為香港警方商業罪案調查科轄下部門，協調警隊各相關部門打擊及預防詐騙之工作。",
    scamCornerLi9: "「反詐騙協調中心」",
    scamCornerLink2:"(網站)",
    scamCornerLi: "「反詐騙協調中心」 (報案電話熱線: 18222)",



    copyright: "©2023 由克裏斯貿易（香港） 有限公司全權擁有"
}