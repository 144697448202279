import { createI18n } from 'vue-i18n' //引入vue-i18n组件
import zhCN from './zh-CN'  // 中文语言包
import zhHant from './zh-Hant'  // 中文语言包
import en from './en'  // 英文语言包

const messages = {
    "zh-CN": zhCN,
    "zh-Hant": zhHant,
    en
};
const i18n = createI18n({
    allowComposition: true,
    locale: "zh-Hant",// getLocale(),
    messages,
    silentFallbackWarn: true,
    silentTranslationWarn: true //去除警告信息
});
export default i18n